<template>
  <div v-if="isAuthenticated">
    <div v-if="!!user">
      <h3>Welcome {{ user }}!</h3>
    </div>
    <div v-else>
      <h3>Welcome!</h3>
    </div>
  </div>
  <div v-else>
    <h3><router-link class="nav-link" :to="{ name: 'SignInPage' }">Login</router-link></h3>
  </div>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";

export default {
  setup() {

    const store = useStore();

    const isAuthenticated = computed(() => {
      return store.getters['auth/isAuthenticated'];
    });
    const user = computed(() => {
      return store.getters['auth/username'];
    });

    return {
      isAuthenticated,
      user
    }
  }
}
</script>
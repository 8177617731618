<template>
  <div class="d-flex flex-column h-100">
    <header>
      <nav-header></nav-header>
    </header>
    <main>
      <router-view></router-view>
    </main>
<!--    <footer class="footer mt-auto ">
      <footer-bar/>
    </footer>-->
  </div>
</template>

<script>
import NavHeader from "@/components/NavHeader.vue";

export default {
  components: {
    NavHeader
  },
  created() {
    const redirect = this.$route.query.redirect;
    this.$store.dispatch('auth/tryLogin')
        .then(() => {
          if (redirect) {
            if (redirect.startsWith('/')) {
              this.$router.replace(`${decodeURIComponent(redirect)}`)
            } else {
              this.$router.replace(`/${decodeURIComponent(redirect)}`)
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap');

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

main {
  padding: 75px 15px 0;
}

header {
  padding: 10px;
}

/*nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

.nav-item .nav-link.active {
  font-weight: bold;
}

.nav-item .nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
}*/
</style>


<!--<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>-->

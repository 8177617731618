<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>
    <h3>API Test</h3>
    <div class="flex flex-col m-auto w-64">
    <button class="btn-blue mb-4" @click="fetch">Fetch Greeting</button>
    <h5 class="mt-4" v-if="greeting">{{ greeting }}</h5>
    <h5 class="mt-4 text-red-500" v-if="error">{{ error }}</h5>
    </div>
  </div>
</template>
<script>
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

import store from "@/store/index.js"
import axios from 'axios';
import {
  ref,
  reactive,
  computed,
  /*, watch*/
} from 'vue';
import { useRouter } from 'vue-router';

export default {
  components: {
    BaseSpinner,
    BaseDialog
  },
  setup() {
    // CANNOT USE this. INTERNALLY

    // reactive is only for objects
    // entire object is reactive, but not the internal variables
    // using an object only to demonstrate usage
    const greeting = reactive({message: 'Click Button!'});
    // simple string reference
    const errorMessage = ref(null);
    const loading = ref(false);

    /*setTimeout(function() {
      greeting.message = 'Hey!';
      error.value = '';
    }, 2000);*/

    // toRefs converts all variables to ref, making them individually watchable
    // not sure why I would want this
    // const greetingRefs = toRefs(greeting)

    const uGreeting = computed(function() {
      if (greeting.message) {
        return greeting.message.toUpperCase();
      } else {
        return null;
      }
    });

    async function fetchGreeting() {
      greeting.message = null;
      errorMessage.value = null;
      loading.value = true;

      //  TELLME: This is the final HOST & URL we are hitting
      // const apiHost = 'api.j2clark.info'
      const apiPath = '/hello'
      const url = process.env.VUE_APP_API_URL + apiPath;

      store.dispatch('auth/signGET', apiPath)
        .then((headers) => {
          axios.get(url, {
            headers: headers
          }).then((response) => {
            loading.value = false;
            greeting.message = response.data.body;
          }).catch((error) => {
            console.error('Axios Error: ' + error)
            errorMessage.value = error.message;
            loading.value = false;
          });
        })
        .catch((error) => {
          console.error('Signing Error: ' + JSON.stringify(error, null, 2));
          /*errorMessage.value = error.message;
          loading.value = false;*/
          useRouter().replace('/signin?redirect=hello');
        });
    }

    function dismissError() {
      errorMessage.value = null
    }

    return {
      isLoading: loading,
      // greeting: greeting,
      error: errorMessage,

      greeting: uGreeting,

      fetch: fetchGreeting,
      handleError: dismissError
    }
  }
}
</script>
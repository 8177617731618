<template>
  <div>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>

    <ul class="list-group">
      <li v-for="address in blocked" :key="address" class="list-group-item">
        {{ address }}
      </li>
    </ul>
  </div>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {useStore} from "vuex";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

export default {
  components: {
    BaseSpinner,
    BaseDialog,
  },
  setup() {

    const store = useStore();

    const isLoading = ref(false);
    const blocked = ref([]);

    onBeforeMount(async () => {
      isLoading.value = true;

      const apiPath = '/email'
      const headers = await store.dispatch('auth/signGET', apiPath);

      const url = process.env.VUE_APP_API_URL + apiPath
      await axios.get(url,{
        headers: headers
      }).then(response => {

        const emails = response.data;

        // for each email address, extract the domain
        // const domains = new Set()

        const addresses = new Set();
        for (const email of emails) {
          const domain = email.split('@')[1]
          // domains.add()
          addresses.add({
            domain: domain,
            address: email
          })
        }

      //   group by domain
        const grouped = {}
        for (const address of addresses) {
          if (grouped[address.domain]) {
            grouped[address.domain].push(address.address)
          } else {
            grouped[address.domain] = [address.address]
          }
        }

        // order by domain
        const ordered = {}
        Object.keys(grouped).sort().forEach(function(key) {
          ordered[key] = grouped[key];
        });

        // order each domain's addresses
        for (const domain in ordered) {
          ordered[domain].sort()
        }

        for (const domain in ordered) {
          for (const address of ordered[domain]) {
            blocked.value.push(address);
          }
        }
      }).catch(error => {
        console.error(error)
      })

      isLoading.value = false;
    });

    return {
      blocked,
      isLoading
    }
  }
}
</script>

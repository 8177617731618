import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AlbumDetailsPage from "@/views/AlbumDetailsPage.vue";
import AlbumsPage from "@/views/AlbumsPage.vue";
import store from "@/store/index.js"
import SignInPage from "@/views/SignInPage.vue";
import SignUpPage from "@/views/SignUpPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import PollyPage from "@/views/PollyPage.vue";
import HelloPage from "@/views/HelloPage.vue";
import ChatsPage from "@/views/ChatsPage.vue";
import ChatDetail from "@/views/ChatDetail.vue";
import ChatList from "@/views/ChatList.vue";
import ChatCreate from "@/views/ChatCreate.vue";
import EmailPage from "@/views/EmailView.vue";
import BlockedEmails from "@/views/emails/BlockedEmails.vue";
import BlockEmail from "@/views/emails/BlockEmail.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/signin',
    name: 'SignInPage',
    component: SignInPage,
    meta: {requiresNotAuth: true}
  },
  {
    path: '/emails',
    name: 'Emails',
    component: EmailPage,
    meta: {requiresAuth: true},
    children: [
      {
        path: 'blocked',
        name: 'BlockedEmails',
        component: BlockedEmails
      },
      {
        path: 'block',
        name: 'BlockEmail',
        component: BlockEmail
      },
      {
        path: ':pathMatch(.*)*',
        redirect: {name: 'BlockEmail'}
      }
    ]
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignUpPage,
    meta: {requiresNotAuth: true}
  },
  {
    path: '/album/:id',
    name: 'AlbumDetailPage',
    component: AlbumDetailsPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/albums',
    name: 'AlbumsPage',
    component: AlbumsPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/polly',
    name: 'PollyPage',
    component: PollyPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/hello',
    name: 'HelloPage',
    component: HelloPage,
    meta: {requiresAuth: true}
  },
  {
    path: '/chats',
    name: 'ChatsPage',
    component: ChatsPage,
    meta: {requiresAuth: true},
    children: [{
      path: '',
      name: 'ListChats',
      component: ChatList
    //     path: ':id',
    //     name: 'ChatPage',
    //     component: ChatPage,
    //     meta: {requiresAuth: true},
    }, {
      path: ':id',
      name: 'ChatDetail',
      component: ChatDetail
    }, {
      path: 'create',
      name: 'CreateChat',
      component: ChatCreate
    }]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:notFound(.*)',
    component: NotFoundPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log('routeGuard for ' + to.path);

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresNotAuth = to.matched.some(record => record.meta.requiresNotAuth);

  // do not use async methods here (like store.dispatch("auth/currentUser")) as we get incorrect value(s)
  const authenticated = store.getters['auth/isAuthenticated']

  if (requiresAuth) {
    // console.log(to.path + ' requires Auth');
    if (authenticated) {
      // console.log('authenticated, redirect to => ' + to.path);
      next();
    } else {
      let redirect = (to.href || '').replace(window.location.origin, '')
      if (redirect.startsWith('/')) {
        redirect = redirect.slice(1)
      }

      console.log(`NOT authenticated, redirect to => /signin?redirect=${encodeURIComponent(redirect)}`);
      next(`/signin?redirect=${encodeURIComponent(redirect)}`);
    }
  } else if (requiresNotAuth) {
    // console.log(to.path + ' requires NotAuth');
    if (!authenticated) {
      // console.log('Not authenticated, redirect to => ' + to.path);
      next();
    } else {
      // console.log('authenticated, redirect to => /');
      next('/');
    }
  } else {
    // console.log('No routeGuard => ' + to.path);
    next();
  }
})

export default router

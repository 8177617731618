<template>
  <div>
    <base-dialog fixed :show="isLoading" title="Blocking...">
      <base-spinner></base-spinner>
    </base-dialog>

    <div class="d-flex flex-row justify-content-center align-content-center">
      <input class=""
             placeholder="Email address ..."
             type="email"
             v-model="address"/>
      <button class="btn btn-danger ms-1" @click="blockAddress">Block</button>
    </div>

  </div>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import { useStore } from 'vuex';
import axios from "axios";
import {useRoute, useRouter} from "vue-router";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export default {
  components: {
    BaseSpinner,
    BaseDialog
  },
  setup() {

    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const address = ref('');

    const error = ref(null);
    const isLoading = ref(false);

    onBeforeMount(async () => {
      const emailAddress = route.query.s;
      if (emailAddress) {
        address.value = htmlDecode(emailAddress);
      }
    })

    const blockAddress = async function () {

      const content = {
        address: address.value
      }

      try {
        isLoading.value = true;

        const apiPath = '/email'
        const headers = await store.dispatch('auth/signPOST', {
          apiPath: apiPath,
          body: content
        });

        const url = process.env.VUE_APP_API_URL + apiPath
        await axios.post(url, content,{
          headers: headers
        }).then(() => {
          address.value = '';
          isLoading.value = false;
          // router.replace("/emails/blocked");
        }).catch(err => {
          console.error('Exception blocking address: ' + err)
          isLoading.value = false
          error.value = err.message;
        });
      } catch (err) {
        console.error('Exception signing request: ' + err);
        isLoading.value = false;
        //    probably not signed in?
        //  todo: we need to pass the address as well
        const redir = `email/block?s=${encodeURIComponent(address.value)}`;
        await router.replace('/signin?redirect=' + redir);
      }
    };

    return {
      address,
      blockAddress,
      isLoading
    }
  }
}
</script>
<template>
  <div class="container">
    <h3>EMAILS</h3>
    <ul class="nav nav-tabs flex-column flex-sm-row">
      <li class="nav-item flex-sm-fill text-sm-center">
        <div class="d-grid">
          <router-link :to="{name: 'BlockedEmails'}" :class="$route.name === 'BlockedEmails' ? 'active' : ''" class="nav-link link-dark btn btn-light fs-3">Blocked</router-link>
        </div>
      </li>
      <li class="nav-item flex-sm-fill text-sm-center">
        <div class="d-grid">
          <router-link :to="{name: 'BlockEmail'}" :class="$route.name === 'BlockEmail' ? 'active' : ''" class="nav-link link-dark btn btn-light fs-3">Block</router-link>
        </div>
      </li>
    </ul>

    <div class="mt-5">
      <router-view/>
    </div>
  </div>
</template>
<script>
export default {
  setup() {

    const selectedTab = 'blocked'

    const selectTab = function (type) {
      this.selectedTab = type
    }

    return {
      selectTab,
      selectedTab
    }
  }
}
</script>
<template>
  <div>
    <base-dialog :show="!!error" title="An error occurred" @close="handleError">
      <p>{{ error.message }}</p>
    </base-dialog>
    <base-dialog fixed :show="isLoading" title="Loading...">
      <base-spinner></base-spinner>
    </base-dialog>
    <h3>Chats</h3>
    <div class="flex flex-col m-auto w-64">
      <ul>
        <li v-for="conversation in conversations" :key="conversation.id">
          <router-link :to="{name: 'ChatDetail', params: {id: conversation.id }}">{{ conversation.participants }}: {{moment(conversation.last)}}</router-link>
        </li>
      </ul>
      <router-link to="chats/create">
        <button class="btn-blue mb-4">New Chat</button>
      </router-link>
      <h5 class="mt-4 text-red-500" v-if="error">{{ error }}</h5>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BaseDialog from "@/components/BaseDialog.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import moment from "moment";

export default {
  components: {
    BaseDialog,
    BaseSpinner,
  },
  data() {
    return {
      conversations: [],
      isLoading: false,
      error: '',
    }
  },
  methods: {
    handleError() {
      this.error = null
    },
    moment(timestamp) {
      if (typeof(timestamp) === "undefined") {
        return ":empty:"
      } else {
        const m = moment(new Date(timestamp));
        return m.fromNow();
      }
    },
    async fetch() {
      this.error = ""
      this.isLoading = true;

      const apiPath = '/conversations'
      const headers = await this.$store.dispatch('auth/signGET', apiPath);

      //  todo: MOVE TO CHATS STORE
      const url = process.env.VUE_APP_API_URL + apiPath
      await axios.get(url, {
        headers: headers
      }).then(response => {
        this.isLoading = false

        const data = response.data;
        // this.conversations = JSON.parse(data);
        this.conversations = data;
      }).catch(err => {
        this.isLoading = false
        console.error(err)
        this.error = err.message;
      });
    }
  },
  async beforeMount() {
    await this.fetch()
  }
}
</script>
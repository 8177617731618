<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light bg-body py-3 fixed-top">
    <div class="container">
      <router-link to="/" class="navbar-brand d-flex align-items-center">
        <span>
          j2clark.info
        </span>
      </router-link>

      <button
          class="navbar-toggler"
          :class="visible ? '' : 'collapsed'"
          :aria-expanded="visible ? 'true' : 'false'"
          @click="visible = !visible">
        <span class="visually-hidden"></span>
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarNav"
           class="collapse navbar-collapse flex-grow-0"
           :class="visible ? 'show' : ''"
      >

        <ul class="navbar-nav mx-auto">
          <li v-if="!isAuthenticated" class="nav-item" >
            <router-link class="nav-link" :class="$route.name === 'SignInPage' ? 'active' : ''" :to="{ name: 'SignInPage' }" @click="visible = false">Login</router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'home' ? 'active' : ''" :to="{ name: 'home' }" @click="visible = false">Home</router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'Emails' ? 'active' : ''" :to="{ name: 'BlockEmail' }" @click="visible = false">Email</router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'AlbumsPage' ? 'active' : ''" :to="{ name: 'AlbumsPage' }" @click="visible = false">Albums</router-link>
          </li>

          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'PollyPage' ? 'active' : ''" :to="{ name: 'PollyPage' }" @click="visible = false">Polly</router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'HelloPage' ? 'active' : ''" :to="{ name: 'HelloPage' }" @click="visible = false">Hello</router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item">
            <router-link class="nav-link" :class="$route.name === 'ChatsPage' ? 'active' : ''" :to="{ name: 'ChatsPage' }" @click="visible = false">Chats</router-link>
          </li>

          <li v-if="isAuthenticated" class="nav-item" >
            <!-- TODO: sub menu of different products -->
            <button class="nav-link" @click="logout">Logout</button>
          </li>
        </ul>

      </div>
    </div>
  </nav>
</template>

<script>
import {useStore} from "vuex";
import {computed, ref} from "vue";
import {useRouter} from "vue-router";

export default {

  setup() {

    const store = useStore();
    const router = useRouter();

    const visible = ref(false);

    const isAuthenticated = computed(() => {
      return store.getters['auth/isAuthenticated'];
    });

    const logout = async () => {
      visible.value = false;
      await store.dispatch("auth/logout");
      await router.push('/signin');
    };

    return {
      visible,
      isAuthenticated,
      logout
    }
  }

  /*methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push('/signin');
    },
    async authenticated() {
      return false;
    }
  },
  computed: {
    isAuthenticated() {
      // do not use async calls (lie this.$store.dispatch("auth/currentUser")) in computed - they return wrong value(s)
      return this.$store.getters['auth/isAuthenticated'];
    }
  }*/
}
</script>
<style lang="scss">
nav {
  /*padding: 30px;*/

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

.nav-item .nav-link.active {
  font-weight: bold;
}

.nav-item .nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -2px;
  left: 0;
}
</style>
